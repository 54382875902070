import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import { FaCopy, FaShareAlt } from 'react-icons/fa'; // React Icons
import Footer from './Footer'; // Import Footer component
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const Dashboard = () => {
  const [isAITradingOn, setAITradingOn] = useState(true); // AI trading toggle state
  const [showCopyMessage, setShowCopyMessage] = useState(false); // State to show message for copying

  // Mock data (replace with actual data from state or props)
  const totalEarnings = 5000;
  const totalWithdrawals = 1500;
  const totalDeposits = 3000;
  const teamDeposits = 500000;
  const aiEarnings = 1200; // Example AI earnings
  const referralEarnings = 800; // Example referral earnings
  const teamEarnings = 500; // Example team AI earnings
  const rank = "Gold"; // Example rank
  const teamRankTarget = 1000000;
  const totalTeamVolume = 12000;
  const rankTarget = 20000;
    // Mock data
    const totalTeamsDeposits = 150000.5; // Example value for total deposits
    const teamsTotalAIEarnings = 75000.25; // Example value for AI earnings
    const leadershipEarnings = 30000.75; // Example value for leadership earnings

  // Initialize the useNavigate hook
  const navigate = useNavigate();

  // Function to copy the referral link
  const copyReferralLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText('www.aialgotraders.com/register/username')
        .then(() => {
          setShowCopyMessage(true); // Show a success message
          setTimeout(() => setShowCopyMessage(false), 3000); // Hide the message after 3 seconds
        });
    } else {
      // Fallback for mobile where clipboard might not work
      const input = prompt("Copy the referral link:", "www.aialgotraders.com/register/username");
      if (input) {
        alert("Referral link copied!");
      }
    }
  };

  // Function to share the referral link
  const shareReferralLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Join My AI Trading Team!',
        url: 'www.aialgotraders.com/register/username',
      }).then(() => {
        alert('Link shared successfully!');
      }).catch(err => alert('Error sharing link'));
    } else {
      alert('Sharing is not supported on this device');
    }
  };

  // Toggle AI Trading ON/OFF
  const toggleAITrading = () => {
    setAITradingOn(!isAITradingOn);
  };

  // Function to handle Withdraw button click
  const handleWithdraw = () => {
    navigate('/withdrawal'); // Navigate to the withdrawal page
  };

  // Function to handle Deposit button click
  const handleDeposit = () => {
    navigate('/deposit'); // Navigate to the deposit page
  };

  return (
    <DashboardLayout>
      <div className="p-6 space-y-8 bg-black animate__animated animate__fadeIn">
        {/* Welcome Section */}
        <h1 className="text-5xl font-extrabold text-white mb-6 text-center animate__animated animate__fadeIn animate__delay-1s">
          Welcome, [User's Name]! 👋
        </h1>


        {/* Balance Section */}
        <div className="flex flex-col md:flex-row gap-8 items-center justify-between">
          <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl w-full md:w-1/2 transform hover:scale-105 transition-transform duration-500 ease-in-out animate__animated animate__fadeIn">
            <p className="text-gray-100 font-semibold">Available Balance</p>
            <h2 className="text-3xl font-bold text-white">
              ${totalEarnings - totalWithdrawals} {/* Available Balance Formula */}
            </h2>
          </div>
          <div className="flex gap-6">
            <button 
              onClick={handleWithdraw} // Trigger Withdraw action
              className="bg-blue-600 text-white px-8 py-3 rounded-lg shadow-md hover:bg-blue-700 transform transition-all duration-300 ease-in-out hover:scale-105">
              Withdraw
            </button>
            <button 
              onClick={handleDeposit} // Trigger Deposit action
              className="bg-purple-600 text-white px-8 py-3 rounded-lg shadow-md hover:bg-purple-700 transform transition-all duration-300 ease-in-out hover:scale-105">
              Deposit
            </button>
          </div>
        </div>



        {/* Quick Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
          <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
            <p className="text-gray-100">Total Earnings</p>
            <h3 className="text-xl font-semibold text-yellow-300">${totalEarnings}</h3>
          </div>
          <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
            <p className="text-gray-100">Total Withdrawals</p>
            <h3 className="text-xl font-semibold text-yellow-300">${totalWithdrawals}</h3>
          </div>
          <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
            <p className="text-gray-100">Total Deposits</p>
            <h3 className="text-xl font-semibold text-yellow-300">${totalDeposits}</h3>
          </div>
        </div>



{/* Today's Earnings Stats Section */}
<div className="mt-8 animate__animated animate__fadeIn animate__delay-1s">
  <h2 className="text-2xl font-semibold text-white">Today's Earnings 💰</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">
    {/* Total Earnings Card */}
    <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">Total Earnings</h3>
      <p className="text-xl text-yellow-300">${aiEarnings + referralEarnings + teamEarnings}</p>
    </div>

    {/* AI Earnings Card */}
    <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">AI Earnings</h3>
      <p className="text-xl text-yellow-300">${aiEarnings}</p>
    </div>

    {/* Referral Earnings Card */}
    <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">Referral Earnings</h3>
      <p className="text-xl text-yellow-300">${referralEarnings}</p>
    </div>

    {/* Team AI Trading Earnings Card */}
    <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:shadow-xl transform transition-all duration-500 ease-in-out hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">Team AI Trading Earnings</h3>
      <p className="text-xl text-yellow-300">${teamEarnings}</p>
    </div>
  </div>
</div>


{/* Progress Stats Section */}
<div className="mt-8 animate__animated animate__fadeIn animate__delay-2s">
  <h2 className="text-2xl font-semibold text-white">Progress Stats 📊</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">

    {/* Current Package Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">Current Package</h3>
      <p className="text-xl font-semibold text-yellow-300">
        {totalDeposits < 1000 ? "Basic Package" : totalDeposits < 10000 ? "Standard Package" : "Premium Package"}
      </p>
      <p className="text-sm text-gray-200">Total Deposits: ${totalDeposits}</p>
    </div>

    {/* Investment Progress Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">Investment Progress</h3>
      <div className="w-full mt-4">
        <div className="relative pt-1">
          <label htmlFor="progress" className="text-sm text-gray-300">Progress to Capping (200%)</label>
          <input
            id="progress"
            type="range"
            value={Math.min(totalEarnings / (totalDeposits * 2) * 100, 100)} // Calculate progress to 200% cap
            className="w-full h-2 bg-blue-400 rounded-full"
            readOnly
          />
          <p className="text-center text-sm text-gray-200">{Math.min(totalEarnings / (totalDeposits * 2) * 100, 100).toFixed(2)}% of 200% capped earnings</p>
        </div>
      </div>
    </div>

    {/* Current Rank Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">Current Rank</h3>
      <p className="text-xl font-semibold text-yellow-300">
        {teamDeposits < 100000 ? "Starter" : teamDeposits < 500000 ? "Bronze" : teamDeposits < 1000000 ? "Silver" : teamDeposits >= 1000000 ? "Gold" : "Platinum"}
      </p>
      <p className="text-sm text-gray-200">Team Deposits: ${teamDeposits}</p>
      <p className="text-sm text-gray-200">Progress to Next Rank: {(Math.min(teamDeposits / (teamRankTarget || 1), 1) * 100).toFixed(2)}%</p>
      <div className="w-full mt-4">
        <div className="relative pt-1">
          <input
            id="rank-progress"
            type="range"
            value={Math.min(teamDeposits / (teamRankTarget || 1), 1) * 100}
            className="w-full h-2 bg-green-400 rounded-full"
            readOnly
          />
        </div>
      </div>
    </div>

    {/* Referral Link Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate__animated animate__fadeIn">
      <h3 className="text-xl font-semibold text-white">Referral Link</h3>
      <div className="flex justify-between items-center">
        <button
          onClick={copyReferralLink}
          className="text-sm bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 transform transition-all duration-300 ease-in-out shadow-md hover:shadow-xl"
        >
          Copy Link
        </button>
        <button
          onClick={shareReferralLink}
          className="text-sm bg-gray-600 text-white px-6 py-2 rounded-lg hover:bg-gray-700 transform transition-all duration-300 ease-in-out shadow-md hover:shadow-xl"
        >
          Share Link
        </button>
      </div>
      {showCopyMessage && <p className="text-sm text-green-500 mt-2">Referral link copied!</p>}
    </div>

  </div>
</div>





{/* AI Investment Portfolio Section */}
<div className="mt-8 animate__animated animate__fadeIn animate__delay-2s">
  <h2 className="text-2xl font-semibold text-white">Your AI Investment Portfolio 📊</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">

    {/* AI Trading Status Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate-float">
      <h3 className="text-xl font-semibold text-white">AI Trading Status</h3>
      <p className={`text-xl font-bold mt-2 text-${isAITradingOn ? 'green' : 'red'}-400`}>
        {isAITradingOn ? "AI Trading ON" : "AI Trading OFF"}
      </p>

      {/* Custom Animated Toggle */}
      <div
        onClick={toggleAITrading}
        className={`mt-4 w-20 h-10 flex items-center bg-gradient-to-r ${
          isAITradingOn ? 'from-blue-500 to-green-400' : 'from-maroon-500 to-red-500'
        } rounded-full p-1 cursor-pointer transition-colors duration-500 shadow-lg hover:shadow-xl`}
      >
        <div
          className={`w-8 h-8 bg-white rounded-full shadow-md transform transition-transform duration-500 ${
            isAITradingOn ? 'translate-x-10 bg-green-400' : 'translate-x-0 bg-red-400'
          }`}
        ></div>
      </div>
    </div>

    {/* Total Teams Deposits Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate-fadeInUp">
      <h3 className="text-xl font-semibold text-white">Total Teams Deposits</h3>
      <p className="text-4xl font-bold text-gray-100 mt-4">
        ${totalTeamsDeposits.toLocaleString()}
      </p>
    </div>

    {/* Teams Total AI Earnings Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate-zoomIn">
      <h3 className="text-xl font-semibold text-white">Teams Total AI Earnings</h3>
      <p className="text-4xl font-bold text-gray-100 mt-4">
        ${teamsTotalAIEarnings.toLocaleString()}
      </p>
    </div>

    {/* Leadership Earnings Card */}
    <div className="relative bg-gradient-to-t from-blue-600 via-indigo-700 to-maroon-600 shadow-lg p-6 rounded-xl hover:shadow-2xl transform transition-all duration-700 hover:scale-105 animate-bounce">
      <h3 className="text-xl font-semibold text-white">Leadership Earnings</h3>
      <p className="text-4xl font-bold text-gray-100 mt-4">
        ${leadershipEarnings.toLocaleString()}
      </p>
    </div>
  </div>
</div>






        <Footer /> {/* Include Footer component */}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
