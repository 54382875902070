import React, { useState } from 'react';
import { motion } from 'framer-motion';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    // Basic email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    console.log('Requesting password reset for:', email);

    // Make the API request to your backend (replace with your actual endpoint)
    try {
      const response = await fetch('/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Password reset link sent! Check your email.');
      } else {
        setError(data.message || 'Error sending reset link. Please try again.');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Network error. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
      <motion.section
        className="w-full max-w-md bg-gradient-to-r from-pink-500 to-purple-600 py-8 px-6 rounded-lg shadow-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 className="text-2xl font-bold text-center mb-8">Reset Password</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full py-3 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-pink-500 text-black"
          />
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {message && <p className="text-green-400 text-sm">{message}</p>}
          <button type="submit" className="w-full py-3 bg-pink-600 rounded-lg hover:bg-pink-700 text-white">
            Send Reset Link
          </button>
        </form>
      </motion.section>
    </div>
  );
}

export default ForgotPasswordPage;
