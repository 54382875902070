// DashboardLayout.js
import React from 'react';
import DashboardHeader from './DashboardHeader'; // Import your header component

const DashboardLayout = ({ children }) => {
  return (
    <div>
      {/* Dashboard Header */}
      <DashboardHeader />

      {/* Page content passed as children */}
      <main className="min-h-screen bg-gray-100">{children}</main>
    </div>
  );
};

export default DashboardLayout;
