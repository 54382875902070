import React from 'react';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer'; // Make sure you have a universal Footer component

const Education = () => {
  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col p-6">
        <div className="flex-grow">
          <h1 className="text-3xl font-bold text-gray-900 animate__animated animate__fadeIn">
            Education
          </h1>
          <p className="mt-4 text-lg text-gray-700 animate__animated animate__fadeIn animate__delay-1s">
            Learn more about cryptocurrency, trading, and the latest market trends in our educational resources.
          </p>

          {/* AiAlgoTraders Academy Section */}
          <div className="mt-8 bg-gradient-to-b from-indigo-600 to-blue-800 p-6 rounded-lg text-white shadow-lg hover:scale-105 transform transition-all duration-300">
            <h2 className="text-2xl font-semibold">AiAlgoTraders Academy - Coming Soon!</h2>
            <p className="mt-4 text-lg">
              Our AiAlgoTraders Academy is launching soon, where we’ll be covering some of the most advanced trading
              topics including:
            </p>
            <ul className="mt-4 list-disc pl-5">
              <li>Advanced Trading Strategies</li>
              <li>AI Trading Algorithms Programming</li>
              <li>Mastering Crypto and Forex Markets</li>
              <li>Understanding Technical Indicators</li>
              <li>Risk Management and Position Sizing</li>
            </ul>
            <p className="mt-4 text-lg">
              The price for the full course is <span className="font-bold text-yellow-400">$1000</span>. All courses will include certifications upon completion, providing you with the credentials you need to take your trading to the next level.
            </p>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default Education;
