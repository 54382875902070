import React, { useState, useEffect } from 'react';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer'; // Universal Footer
import { FaMoneyBillWave } from 'react-icons/fa'; // Icon for deposit

const Deposit = () => {
  const [depositAmount, setDepositAmount] = useState('');
  const [recentDeposits, setRecentDeposits] = useState([]);

  // Mock function to fetch recent deposits (replace with API call)
  useEffect(() => {
    const fetchRecentDeposits = async () => {
      // Replace with actual API call to get recent deposits
      const deposits = [
        { id: 1, amount: 50, date: '2024-12-10' },
        { id: 2, amount: 120, date: '2024-12-08' },
        { id: 3, amount: 200, date: '2024-12-05' },
      ];
      setRecentDeposits(deposits);
    };

    fetchRecentDeposits();
  }, []);

  // Handle deposit button click
  const handleDeposit = () => {
    if (depositAmount > 0) {
      alert(`Depositing ${depositAmount} USDT via Cryptomus...`);
      // Add API call here for actual deposit
    } else {
      alert('Please enter a valid deposit amount.');
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6 space-y-8 bg-black animate__animated animate__fadeIn">
        {/* Deposit Section */}
        <h1 className="text-3xl font-bold text-white">Deposit</h1>
        <p className="mt-4 text-lg text-gray-400">
          Make a deposit to fund your account. Enter the amount and hit the deposit button.
        </p>

        <div className="mt-6">
          {/* Deposit Amount Section */}
          <div className="flex flex-col gap-4 items-center justify-center">
            <input
              type="number"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
              className="w-full max-w-md p-4 text-xl rounded-md border border-gray-300 focus:ring-2 focus:ring-purple-600"
              placeholder="Enter Deposit Amount (USDT)"
            />
            <button
              onClick={handleDeposit}
              className="bg-purple-600 text-white px-8 py-3 rounded-lg shadow-md hover:bg-purple-700 transform transition-all duration-300 ease-in-out hover:scale-105"
            >
              <FaMoneyBillWave className="inline-block mr-2" />
              Deposit
            </button>
          </div>
        </div>

        {/* Recent Deposits Section */}
        <div className="mt-8">
          <h2 className="text-2xl font-semibold text-white">Recent Deposits</h2>
          <div className="mt-4 space-y-4">
            {recentDeposits.length > 0 ? (
              recentDeposits.map((deposit) => (
                <div key={deposit.id} className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl hover:scale-105 transform transition-all duration-300 ease-in-out animate__animated animate__fadeIn">
                  <p className="text-gray-100">Amount: <span className="text-white">${deposit.amount} USDT</span></p>
                  <p className="text-gray-300">Date: {deposit.date}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No recent deposits yet.</p>
            )}
          </div>
        </div>

        {/* Universal Footer */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default Deposit;
