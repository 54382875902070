import React from 'react';
import { FiCopy, FiShare } from 'react-icons/fi';  // React Icons
import DashboardLayout from './DashboardLayout';
import Footer from './Footer';  // Import the universal Footer

const Referrals = () => {
  // Sample data for referral team (to be replaced with actual dynamic data)
  const referralDetails = [
    { no: 1, name: 'John Doe', dateJoined: '2024-12-01', sponsor: 'Your Name', phoneNumber: '+1234567890', generation: 1, capital: '$500.00' },
    { no: 2, name: 'Jane Smith', dateJoined: '2024-12-05', sponsor: 'John Doe', phoneNumber: '+0987654321', generation: 2, capital: '$300.00' },
    { no: 3, name: 'Emily Johnson', dateJoined: '2024-12-10', sponsor: 'Jane Smith', phoneNumber: '+1122334455', generation: 3, capital: '$700.00' },
    // Add more referral data as needed
  ];

  const totalReferrals = referralDetails.length;
  const totalCapital = referralDetails.reduce((sum, referral) => sum + parseFloat(referral.capital.replace('$', '')), 0).toFixed(2);

  const referralLink = `https://aialgotraders.com/register/username`; // Replace 'username' with dynamic username

  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    alert('Referral link copied to clipboard!');
  };

  const handleShare = () => {
    // Here we can implement share functionality if needed
    alert(`Share this link: ${referralLink}`);
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        <h1 className="text-4xl font-extrabold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-pink-600 animate__animated animate__fadeIn">Referrals</h1>
        <p className="mt-4 text-lg text-gray-700 animate__animated animate__fadeIn animate__delay-1s">
          Track your referral progress and view referral bonuses.
        </p>

        {/* Total Team Details - Mobile-Optimized Grid */}
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg text-white shadow-xl transform hover:scale-105 transition-all duration-300">
            <p className="text-lg">Total Referrals</p>
            <h3 className="text-4xl font-bold">{totalReferrals}</h3>
          </div>
          <div className="bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg text-white shadow-xl transform hover:scale-105 transition-all duration-300">
            <p className="text-lg">Total Team Capital</p>
            <h3 className="text-4xl font-bold">${totalCapital}</h3>
          </div>
          <div className="bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg text-white shadow-xl transform hover:scale-105 transition-all duration-300">
            <p className="text-lg">Referral Link</p>
            <div className="flex flex-wrap items-center">
              <h3 className="text-2xl font-bold mr-4">{referralLink}</h3>
              <button 
                className="ml-4 bg-green-500 text-white p-2 rounded-md flex items-center transform hover:scale-105 transition-all duration-300 shadow-md"
                onClick={handleCopy}
              >
                <FiCopy className="mr-2" /> Copy Link
              </button>
              <button 
                className="ml-4 bg-blue-500 text-white p-2 rounded-md flex items-center transform hover:scale-105 transition-all duration-300 shadow-md"
                onClick={handleShare} 
              >
                <FiShare className="mr-2" /> Share
              </button>
            </div>
          </div>
        </div>

        {/* Referrals Table - Mobile-Optimized */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-green-400 to-pink-600">Referral Details</h2>
          <div className="overflow-x-auto mt-4">
            <table className="min-w-full table-auto shadow-md bg-white rounded-lg">
              <thead className="bg-gradient-to-b from-indigo-500 to-blue-600 text-white animate__animated animate__fadeIn">
                <tr>
                  <th className="px-4 py-2 text-left text-sm sm:text-base">No</th>
                  <th className="px-4 py-2 text-left text-sm sm:text-base">Name</th>
                  <th className="px-4 py-2 text-left text-sm sm:text-base">Date Joined</th>
                  <th className="px-4 py-2 text-left text-sm sm:text-base">Sponsor</th>
                  <th className="px-4 py-2 text-left text-sm sm:text-base">Phone (WhatsApp)</th>
                  <th className="px-4 py-2 text-left text-sm sm:text-base">Generation</th>
                  <th className="px-4 py-2 text-left text-sm sm:text-base">Capital</th>
                </tr>
              </thead>
              <tbody>
                {referralDetails.map((referral, index) => (
                  <tr key={index} className="border-t hover:bg-gray-100 animate__animated animate__fadeIn animate__delay-2s">
                    <td className="px-4 py-2">{referral.no}</td>
                    <td className="px-4 py-2">{referral.name}</td>
                    <td className="px-4 py-2">{referral.dateJoined}</td>
                    <td className="px-4 py-2">{referral.sponsor}</td>
                    <td className="px-4 py-2">{referral.phoneNumber}</td>
                    <td className="px-4 py-2">{referral.generation}</td>
                    <td className="px-4 py-2">{referral.capital}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Use the universal Footer */}
      <Footer />
    </DashboardLayout>
  );
};

export default Referrals;
