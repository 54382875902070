import React from 'react';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer'; // Universal Footer
import { FiDownload, FiUpload } from 'react-icons/fi'; // Icons for download and upload

const Documents = () => {
  // Sample data for documents
  const documents = [
    { title: 'Company Presentation', url: '/files/Company_Presentation.pdf' }, 
    { title: 'Policy Update', url: '/files/Policy_Update.pdf' }, 
    { title: 'Terms & Conditions', url: '/files/Terms_Conditions.pdf' }, 
    // Add more documents as needed
  ];

  const isAdmin = true; // Set this dynamically based on user role

  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col p-6">
        <div className="flex-grow">
          <h1 className="text-3xl font-bold text-gray-900 animate__animated animate__fadeIn">
            Documents
          </h1>
          <p className="mt-4 text-lg text-gray-700 animate__animated animate__fadeIn animate__delay-1s">
            Download or view important documents below. Keep track of all official materials.
          </p>

          {/* Documents List */}
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {documents.map((doc, index) => (
              <div
                key={index}
                className="bg-gradient-to-b from-indigo-600 to-blue-800 p-6 rounded-lg text-white shadow-lg hover:scale-105 transform transition-all duration-300"
              >
                <div className="flex justify-between items-center">
                  <p className="text-xl font-semibold">{doc.title}</p>
                  <a
                    href={doc.url}
                    download
                    className="bg-green-500 p-2 rounded-md text-white flex items-center transform hover:scale-110 transition-all duration-300"
                  >
                    <FiDownload className="mr-2" /> Download
                  </a>
                </div>
              </div>
            ))}
          </div>

        </div>

        {/* Footer */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default Documents;
