import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

function LoginPage() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Valid Email is required';
    }
    if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError('');
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      console.log('Logging in with:', formData);
      const response = { success: true }; // Mock server response
      if (response.success) {
        navigate('/dashboard');
      } else {
        setLoginError('Invalid email or password');
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
      <motion.section
        className="w-full max-w-md bg-gradient-to-r from-green-500 to-blue-600 py-8 px-6 rounded-lg shadow-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2 className="text-3xl font-bold text-center text-white mb-8">Welcome Back!</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email Input */}
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address"
            className="w-full py-3 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 text-black"
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

          {/* Password Input */}
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            className="w-full py-3 px-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 text-black"
          />
          {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}

          {/* Forgot Password Link */}
          <div className="flex justify-between items-center text-sm">
            <Link to="/forgot-password" className="text-green-300 hover:underline">
              Forgot Password?
            </Link>
            <Link to="/register" className="text-pink-300 hover:underline">
              Don't have an account? Register
            </Link>
          </div>

          {/* Submit Button */}
          <button type="submit" className="w-full py-3 mt-6 bg-green-600 rounded-lg hover:bg-green-700 text-white">
            Login
          </button>

          {loginError && <p className="text-red-500 text-center mt-4">{loginError}</p>}
        </form>
      </motion.section>
    </div>
  );
}

export default LoginPage;
