import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer';

const KYC = () => {
  const [status, setStatus] = useState("pending");  // Initial status
  const [isSubmitted, setIsSubmitted] = useState(false);  // To track form submission
  const [isApproved, setIsApproved] = useState(false);  // To track if KYC is approved

  const handleSubmit = (e) => {
    e.preventDefault();
    if (status === "approved") return; // Prevent resubmitting if already approved
    
    setStatus("waiting");  // Set status to 'waiting' when form is submitted
    setIsSubmitted(true);  // Form is submitted, so we hide the form
    // Simulate approval after 3 seconds (You can replace this with an API call)
    setTimeout(() => {
      setStatus("approved"); // Change status to approved after 3 seconds
      setIsApproved(true);  // Mark as approved, disable further form submissions
    }, 3000);
  };

  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col p-6">
        <div className="flex-grow">
          <h1 className="text-3xl font-bold text-gray-900 animate__animated animate__fadeIn">
            KYC Verification
          </h1>
          <p className="mt-4 text-lg text-gray-700 animate__animated animate__fadeIn animate__delay-1s">
            Please complete your KYC (Know Your Customer) verification to access all features.
          </p>

          {/* KYC Form Section */}
          {!isApproved ? (
            <div className="mt-8 bg-gradient-to-b from-blue-600 to-indigo-800 p-6 rounded-lg text-white shadow-lg hover:scale-105 transform transition-all duration-300">
              <h2 className="text-2xl font-semibold">Personal Information</h2>
              <form className="mt-4 space-y-6" onSubmit={handleSubmit}>
                {/* Name Fields */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="firstName" className="block text-sm font-semibold">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      placeholder="John"
                      className="w-full p-2 rounded-md border bg-white text-gray-900"
                      disabled={status === "approved"}
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="block text-sm font-semibold">Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      placeholder="Doe"
                      className="w-full p-2 rounded-md border bg-white text-gray-900"
                      disabled={status === "approved"}
                    />
                  </div>
                </div>

                {/* Date of Birth */}
                <div>
                  <label htmlFor="dob" className="block text-sm font-semibold">Date of Birth</label>
                  <input
                    type="date"
                    id="dob"
                    className="w-full p-2 rounded-md border bg-white text-gray-900"
                    disabled={status === "approved"}
                  />
                </div>

                {/* File Upload Section */}
                <div className="mt-6">
                  <h3 className="text-lg font-semibold">Upload Documents</h3>
                  <div className="mt-2 space-y-2">
                    <label className="block text-sm">Proof of Identity (e.g., Passport, National ID)</label>
                    <input
                      type="file"
                      className="w-full p-2 rounded-md bg-white text-gray-900"
                      disabled={status === "approved"}
                    />
                    <label className="block text-sm">Proof of Address (e.g., Utility Bill, Bank Statement)</label>
                    <input
                      type="file"
                      className="w-full p-2 rounded-md bg-white text-gray-900"
                      disabled={status === "approved"}
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="mt-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 transition-all duration-300"
                  disabled={status === "approved"}
                >
                  Submit KYC
                </button>
              </form>
            </div>
          ) : (
            // Show status after form submission
            <div className="mt-8 bg-gradient-to-b from-gray-600 to-gray-800 p-6 rounded-lg text-white shadow-lg">
              <h2 className="text-2xl font-semibold">KYC Status</h2>
              <p className={`mt-4 text-lg ${status === 'approved' ? 'text-green-500' : 'text-yellow-400'}`}>
                {status === 'approved'
                  ? 'Your KYC is approved! 🎉'
                  : status === 'waiting'
                  ? 'Your KYC is under review. Please wait for approval... ⏳'
                  : 'Your KYC is pending... 🔄'}
              </p>
            </div>
          )}
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default KYC;
