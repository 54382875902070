import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import DashboardLayout from './DashboardLayout';
import { FaCopy, FaShareAlt } from 'react-icons/fa';
import Footer from './Footer';

const Financials = () => {
  const [activeTab, setActiveTab] = useState('withdrawals');
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const handleBuyNow = () => {
    navigate('/deposit'); // Navigate to the Deposit page
  };

  const handleWithdraw = () => {
    navigate('/withdrawal'); // Navigate to the Withdrawal page
  };

  return (
    <DashboardLayout>
      <div className="financials-page bg-black min-h-screen text-white">
        {/* Navigation Tabs */}
        <div className="tabs flex flex-wrap justify-center bg-black py-4">
          <button
            className={`tab-item mx-4 px-6 py-2 rounded-full text-lg transition-all duration-300 ${
              activeTab === 'withdrawals' ? 'bg-gradient-to-b from-indigo-500 to-blue-600 border-b-4 border-pink-500' : 'bg-gradient-to-b from-gray-700 to-gray-900'
            } hover:bg-gradient-to-b hover:from-indigo-500 hover:to-blue-600`}
            onClick={() => setActiveTab('withdrawals')}
          >
            Withdrawals
          </button>
          <button
            className={`tab-item mx-4 px-6 py-2 rounded-full text-lg transition-all duration-300 ${
              activeTab === 'aiCapital' ? 'bg-gradient-to-b from-indigo-500 to-blue-600 border-b-4 border-pink-500' : 'bg-gradient-to-b from-gray-700 to-gray-900'
            } hover:bg-gradient-to-b hover:from-indigo-500 hover:to-blue-600`}
            onClick={() => setActiveTab('aiCapital')}
          >
            AI Capital
          </button>
          <button
            className={`tab-item mx-4 px-6 py-2 rounded-full text-lg transition-all duration-300 ${
              activeTab === 'earnings' ? 'bg-gradient-to-b from-indigo-500 to-blue-600 border-b-4 border-pink-500' : 'bg-gradient-to-b from-gray-700 to-gray-900'
            } hover:bg-gradient-to-b hover:from-indigo-500 hover:to-blue-600`}
            onClick={() => setActiveTab('earnings')}
          >
            Earnings
          </button>
        </div>

        {/* Content Sections */}
        <div className="content mt-8 px-4 sm:px-6 md:px-12 lg:px-24">
          {activeTab === 'withdrawals' && <Withdrawals handleWithdraw={handleWithdraw} />}
          {activeTab === 'aiCapital' && <AiCapital handleBuyNow={handleBuyNow} />}
          {activeTab === 'earnings' && <Earnings />}
        </div>

        {/* Footer Component */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

const TransactionHistory = ({ transactions, title }) => (
  <div className="transaction-history mt-8">
    <h3 className="text-xl font-bold mb-4">{title}</h3>
    <ul className="space-y-4">
      {transactions.map((transaction, idx) => (
        <li
          key={idx}
          className="bg-gradient-to-b from-indigo-500 to-blue-600 p-4 rounded flex justify-between items-center"
        >
          <span className="text-white">Date: {transaction.date}</span>
          <span className="text-white">Amount: {transaction.amount}</span>
          <span className="text-white">Status: {transaction.status}</span>
        </li>
      ))}
    </ul>
    <button className="mt-4 bg-gradient-to-b from-indigo-500 to-blue-600 hover:bg-gradient-to-b hover:from-indigo-600 hover:to-blue-700 text-white py-2 px-4 rounded">
      Load More
    </button>
  </div>
);

const Withdrawals = ({ handleWithdraw }) => (
  <div className="withdrawals-section">
    <h2 className="text-2xl font-bold mb-4 text-white">Withdrawals</h2>
    <div className="balance-card bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg mb-6">
      <p className="text-lg text-white">Current Balance</p>
      <h3 className="text-4xl font-bold text-white">$1,250.00</h3>
      <button
        onClick={handleWithdraw}
        className="bg-gradient-to-b from-indigo-500 to-blue-600 hover:bg-gradient-to-b hover:from-indigo-600 hover:to-blue-700 text-white py-2 px-6 rounded mt-4"
      >
        Withdraw
      </button>
    </div>
    <TransactionHistory
      title="Last 10 Transactions"
      transactions={[
        { date: '2024-12-14', amount: '$50.00', status: 'Completed' },
        { date: '2024-12-13', amount: '$25.00', status: 'Completed' },
        { date: '2024-12-12', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-11', amount: '$200.00', status: 'Completed' },
        { date: '2024-12-10', amount: '$150.00', status: 'Completed' },
        { date: '2024-12-09', amount: '$80.00', status: 'Pending' },
        { date: '2024-12-08', amount: '$90.00', status: 'Completed' },
        { date: '2024-12-07', amount: '$40.00', status: 'Pending' },
        { date: '2024-12-06', amount: '$70.00', status: 'Completed' },
        { date: '2024-12-05', amount: '$120.00', status: 'Completed' },
      ]}
    />
  </div>
);

const AiCapital = ({ handleBuyNow }) => (
  <div className="aiCapital-section">
    <h2 className="text-2xl font-bold mb-4 text-white">AI Capital</h2>
    <div className="current-package bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg mb-6">
      <p className="text-lg text-white">Current Package</p>
      <h3 className="text-4xl font-bold text-grey-500">Basic AI</h3>
      <p className="text-gray-400 text-white">Total AI Purchased: 250 AI</p>
    </div>
    <div className="package-options grid grid-cols-1 md:grid-cols-3 gap-6">
      {['Basic', 'Standard', 'Premium'].map((packageType, idx) => (
        <div
          key={idx}
          className="package-card bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg text-center"
        >
          <h3 className="text-2xl font-bold text-pink-500 text-white">{packageType} AI Capital</h3>
          <p className="text-gray-400 mt-2 text-white">
            Daily AI Profit: {packageType === 'Basic' ? '0.5%' : packageType === 'Standard' ? '0.7%' : '1%'}
          </p>
          <p className="text-gray-400 mt-2 text-white">
            Investment Range: {packageType === 'Basic' ? '$100 - $999' : packageType === 'Standard' ? '$1000 - $9999' : 'Above $10,000'}
          </p>
          <button
            onClick={handleBuyNow}
            className="mt-4 bg-gradient-to-b from-indigo-500 to-blue-600 hover:bg-gradient-to-b hover:from-indigo-600 hover:to-blue-700 text-white py-2 px-6 rounded"
          >
            Buy Now
          </button>
        </div>
      ))}
    </div>
    <TransactionHistory
      title="Last 10 Deposits"
      transactions={[
        { date: '2024-12-14', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-13', amount: '$50.00', status: 'Pending' },
        { date: '2024-12-12', amount: '$200.00', status: 'Completed' },
        { date: '2024-12-11', amount: '$150.00', status: 'Completed' },
        { date: '2024-12-10', amount: '$75.00', status: 'Pending' },
        { date: '2024-12-09', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-08', amount: '$250.00', status: 'Completed' },
        { date: '2024-12-07', amount: '$60.00', status: 'Pending' },
        { date: '2024-12-06', amount: '$90.00', status: 'Completed' },
        { date: '2024-12-05', amount: '$120.00', status: 'Completed' },
      ]}
    />
  </div>
);

const Earnings = () => (
  <div className="earnings-section">
    <h2 className="text-2xl font-bold mb-4 text-white">Earnings</h2>
    <div className="earnings-summary grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div className="bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg">
        <p className="text-lg text-white">Total Earnings</p>
        <h3 className="text-4xl font-bold text-white">$5,000.00</h3>
      </div>
      <div className="bg-gradient-to-b from-indigo-500 to-blue-600 p-6 rounded-lg">
        <p className="text-lg text-white">AI Capital Earnings</p>
        <h3 className="text-4xl font-bold text-white">$1,200.00</h3>
      </div>
    </div>
    <TransactionHistory
      title="Recent Earnings"
      transactions={[
        { date: '2024-12-14', amount: '$50.00', status: 'Completed' },
        { date: '2024-12-13', amount: '$25.00', status: 'Completed' },
        { date: '2024-12-12', amount: '$100.00', status: 'Completed' },
        { date: '2024-12-11', amount: '$200.00', status: 'Completed' },
        { date: '2024-12-10', amount: '$150.00', status: 'Completed' },
      ]}
    />
  </div>
);

export default Financials;
