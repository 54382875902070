import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-pink-500 via-purple-600 to-black text-white p-4 flex justify-between items-center shadow-xl transition-all duration-500 ease-in-out transform hover:scale-105 relative">
      {/* Logo */}
      <Link to="/" className="w-36 transform transition-transform duration-500 hover:scale-110">
        <img src={logo} alt="AlgoTraderAi" className="w-full" />
      </Link>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex space-x-8">
        <a
          href="#about"
          className="hover:text-pink-300 hover:scale-105 transition-all duration-300 transform"
          style={{ fontFamily: 'Base Neue', fontWeight: 'bold' }}
        >
          About
        </a>
        <a
          href="#services"
          className="hover:text-pink-300 hover:scale-105 transition-all duration-300 transform"
          style={{ fontFamily: 'Base Neue', fontWeight: 'bold' }}
        >
          Services
        </a>
        <Link
          to="/login"
          className="hover:text-pink-300 hover:scale-105 transition-all duration-300 transform"
          style={{ fontFamily: 'Base Neue', fontWeight: 'bold' }}
        >
          Login
        </Link>
        <Link
          to="/register"
          className="hover:bg-pink-500 hover:text-white bg-purple-600 text-white px-6 py-2 rounded-lg transition-all duration-300 transform hover:scale-105"
          style={{ fontFamily: 'PP Fraktion Mono', fontWeight: 'bold' }}
        >
          Sign Up
        </Link>
      </nav>

      {/* Mobile Menu Button */}
      <button
        className="md:hidden text-white hover:animate-pulse"
        onClick={toggleMenu}
        style={{ fontFamily: 'PP Fraktion Mono' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6 transition-all duration-300 transform"
        >
          {isMobileMenuOpen ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          )}
        </svg>
      </button>

      {/* Mobile Navigation */}
      {isMobileMenuOpen && (
        <div className="absolute top-16 right-0 bg-gradient-to-r from-pink-500 via-purple-600 to-black text-white w-48 shadow-xl p-4 space-y-4 md:hidden rounded-lg transform transition-all duration-500 ease-in-out z-50">
          <button
            className="absolute top-2 right-2 text-white"
            onClick={toggleMenu}
            style={{ fontFamily: 'PP Fraktion Mono' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-8 h-8 transform transition-all duration-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <a
            href="#about"
            className="block hover:text-pink-300 hover:scale-105 transition-all duration-300 transform"
            style={{ fontFamily: 'Base Neue', fontWeight: 'bold' }}
          >
            About
          </a>
          <a
            href="#services"
            className="block hover:text-pink-300 hover:scale-105 transition-all duration-300 transform"
            style={{ fontFamily: 'Base Neue', fontWeight: 'bold' }}
          >
            Services
          </a>
          <Link
            to="/login"
            className="block hover:text-pink-300 hover:scale-105 transition-all duration-300 transform"
            style={{ fontFamily: 'Base Neue', fontWeight: 'bold' }}
          >
            Login
          </Link>
          <Link
            to="/register"
            className="block hover:bg-pink-500 hover:text-white bg-purple-600 text-white px-4 py-2 rounded-lg transition-all duration-300 transform hover:scale-105"
            style={{ fontFamily: 'PP Fraktion Mono', fontWeight: 'bold' }}
          >
            Sign Up
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;
