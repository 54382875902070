import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer';

const Withdrawal = () => {
  const [balance, setBalance] = useState(1000); // Sample account balance, replace with dynamic data
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [status, setStatus] = useState('');
  const [recentWithdrawals, setRecentWithdrawals] = useState([
    { amount: 100, status: 'Completed', date: '2024-12-10', address: 'TXYZ12345' },
    { amount: 50, status: 'Pending', date: '2024-12-08', address: 'TABC67890' },
  ]); // Sample recent withdrawals

  const navigate = useNavigate();

  // Validate if the wallet address is in USDT TRC20 format (basic check)
  const isValidWalletAddress = (address) => {
    // TRC20 addresses usually start with "T" and are 34 characters long
    return address.startsWith('T') && address.length === 34;
  };

  // Handle withdrawal submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseFloat(withdrawAmount) <= balance) {
      if (isValidWalletAddress(walletAddress)) {
        setStatus('Withdrawal Submitted. Please wait for confirmation.');

        // Simulate withdrawal process
        setTimeout(() => {
          setBalance(balance - parseFloat(withdrawAmount)); // Deduct from balance
          setStatus('Withdrawal Successful!');
          
          // Add the new withdrawal to the recent withdrawals
          setRecentWithdrawals((prevWithdrawals) => [
            ...prevWithdrawals,
            {
              amount: parseFloat(withdrawAmount),
              status: 'Completed',
              date: new Date().toLocaleDateString(),
              address: walletAddress,
            },
          ]);

          // Redirect after success
          navigate('/withdrawal');
        }, 2000); // Simulate a delay for the withdrawal process
      } else {
        setStatus('Invalid wallet address. Please enter a valid USDT TRC20 address.');
      }
    } else {
      setStatus('Insufficient funds.');
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6 space-y-8 bg-black animate__animated animate__fadeIn">
        <h1 className="text-3xl font-bold text-white mb-6 text-center">
          Withdrawal
        </h1>

        {/* Withdrawal Form */}
        <div className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-6 rounded-xl w-full md:w-1/2 mx-auto transform hover:scale-105 transition-transform duration-500 ease-in-out animate__animated animate__fadeIn">
          <p className="text-gray-100 font-semibold">Available Balance: ${balance}</p>
          <form onSubmit={handleSubmit} className="space-y-4 mt-6">
            <div className="flex flex-col gap-4">
              <label htmlFor="withdrawAmount" className="text-gray-200">Withdrawal Amount</label>
              <input
                type="number"
                id="withdrawAmount"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                className="p-4 border border-gray-300 rounded-md bg-gray-50 focus:ring-2 focus:ring-purple-600 transition-colors duration-300 ease-in-out"
                required
              />
            </div>
            <div className="flex flex-col gap-4">
              <label htmlFor="walletAddress" className="text-gray-200">Wallet Address (USDT TRC20)</label>
              <input
                type="text"
                id="walletAddress"
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
                className="p-4 border border-gray-300 rounded-md bg-gray-50 focus:ring-2 focus:ring-purple-600 transition-colors duration-300 ease-in-out"
                required
              />
            </div>
            <button
              type="submit"
              className="bg-purple-600 text-white px-8 py-3 rounded-lg shadow-md hover:bg-purple-700 transform transition-all duration-300 ease-in-out hover:scale-105 w-full"
            >
              Submit Withdrawal
            </button>
          </form>
          {status && (
            <div className="mt-4 text-white">
              {status}
            </div>
          )}
        </div>

        {/* Recent Withdrawals Section */}
        <div className="mt-8 animate__animated animate__fadeIn animate__delay-1s">
          <h2 className="text-2xl font-semibold text-white mb-4">Recent Withdrawals</h2>
          <div className="space-y-4">
            {recentWithdrawals.map((withdrawal, index) => (
              <div key={index} className="bg-gradient-to-t from-blue-600 to-purple-600 shadow-lg p-4 rounded-xl">
                <div className="flex justify-between items-center">
                  <p className="text-gray-100">Amount: ${withdrawal.amount}</p>
                  <p className={`text-sm ${withdrawal.status === 'Completed' ? 'text-green-500' : 'text-yellow-500'}`}>
                    {withdrawal.status}
                  </p>
                </div>
                <p className="text-gray-100 text-sm mt-2">Date: {withdrawal.date}</p>
                <p className="text-gray-100 text-sm mt-2">Wallet Address: {withdrawal.address}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Footer Component */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default Withdrawal;
