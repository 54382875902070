import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer';
import { FiArrowRightCircle } from 'react-icons/fi';

const Trading = () => {
  const [amount, setAmount] = useState(1000); // Default $1000 for deposit

  // Handle deposit submission
  const handleDepositSubmit = () => {
    alert(`Deposit of $${amount} received. Please submit your MT5 trading account details.`);
  };

  return (
    <DashboardLayout>
      <div className="bg-gradient-to-b from-blue-600 via-indigo-700 to-purple-800 min-h-screen p-8 text-white font-sans">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-5xl font-extrabold">AiAlgo Trading</h1>
          <p className="text-xl mt-4">Maximize profits with AI trading. 50-120% monthly returns.</p>
        </div>

        {/* Subscription Section */}
        <div className="bg-gradient-to-r from-blue-500 to-purple-700 p-8 rounded-3xl shadow-xl hover:scale-105 transform transition-all duration-500">
          <h2 className="text-4xl font-bold">💰 Subscribe: $1000/month</h2>
          <p className="text-lg mt-4">Your profits, your control. Deposit now and let AI handle the rest.</p>
          
          <div className="mt-6 flex flex-col items-center space-y-4">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="w-full p-5 text-2xl rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Deposit Amount"
              min="1000"
            />
            <button
              onClick={handleDepositSubmit}
              className="bg-gradient-to-r from-maroon-600 to-blue-600 text-white py-3 px-8 rounded-xl text-xl transform hover:scale-110 transition duration-300"
            >
              <FiArrowRightCircle className="mr-2 inline" /> Submit Deposit
            </button>
            <p className="text-2xl">Deposit: ${amount}</p>
          </div>
        </div>

        {/* Broker Account Section */}
        <div className="bg-gradient-to-r from-purple-700 via-blue-600 to-maroon-500 p-8 mt-12 rounded-3xl shadow-xl hover:scale-105 transform transition-all duration-500">
          <h2 className="text-4xl font-bold">🚀 Open Broker Account</h2>
          <p className="text-lg mt-4">Sign up through trusted brokers and start trading with MT5.</p>

          <a
            href="https://yourbrokerlink.com"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-blue-600 to-purple-800 text-white py-3 px-8 rounded-xl text-xl mt-6 inline-block transform hover:scale-110 transition duration-300"
          >
            Open Broker Account
          </a>
        </div>

        {/* MT5 Account Submission Section */}
        <div className="mt-12 bg-gradient-to-r from-blue-500 to-indigo-800 p-8 rounded-3xl shadow-xl hover:scale-105 transform transition-all duration-500">
          <h2 className="text-4xl font-bold">🔑 Submit MT5 Details</h2>
          <p className="text-lg mt-4">Once your deposit is confirmed, provide your MT5 account details to start trading.</p>

          <form className="bg-white text-black p-8 mt-6 rounded-xl shadow-lg">
            <div className="mb-6">
              <input
                type="text"
                className="w-full p-5 text-xl rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="MT5 Account Number"
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                className="w-full p-5 text-xl rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Broker Name"
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                className="w-full p-5 text-xl rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="MT5 Server"
              />
            </div>
            <div className="mb-6">
              <input
                type="password"
                className="w-full p-5 text-xl rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="MT5 Password"
              />
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-maroon-600 to-blue-600 text-white py-3 px-8 rounded-xl text-xl transform hover:scale-110 transition duration-300"
            >
              Submit Details
            </button>
          </form>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default Trading;
