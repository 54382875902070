import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer'; // Ensure you have a universal Footer component

const Settings = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    phoneNumber: '',
  });

  const [securityData, setSecurityData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [profileStatus, setProfileStatus] = useState('');
  const [securityStatus, setSecurityStatus] = useState('');

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleSecurityChange = (e) => {
    const { name, value } = e.target;
    setSecurityData({
      ...securityData,
      [name]: value,
    });
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    // Check if all fields are filled
    if (!profileData.name || !profileData.phoneNumber) {
      setProfileStatus('Please fill in all the fields.');
      return;
    }

    // Simulate profile update success
    setProfileStatus('Profile updated successfully!');
    setProfileData({
      name: '',
      phoneNumber: '',
    });
  };

  const handleSecuritySubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!securityData.oldPassword || !securityData.newPassword || !securityData.confirmNewPassword) {
      setSecurityStatus('Please fill in all the fields.');
      return;
    }

    if (securityData.newPassword !== securityData.confirmNewPassword) {
      setSecurityStatus('Passwords do not match!');
      return;
    }

    // Simulate password update success
    setSecurityStatus('Password updated successfully!');
    setSecurityData({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
  };

  return (
    <DashboardLayout>
      <div className="min-h-screen flex flex-col p-6">
        <div className="flex-grow">
          <h1 className="text-3xl font-bold text-gray-900">Settings</h1>
          <p className="mt-4 text-lg text-gray-700">
            Update your profile and account settings.
          </p>

          {/* Profile Settings Section */}
          <div className="mt-8 bg-gradient-to-b from-blue-600 to-indigo-800 p-6 rounded-lg text-white shadow-lg">
            <h2 className="text-2xl font-semibold">Profile Settings</h2>
            <form className="space-y-6" onSubmit={handleProfileSubmit}>
              {/* Name */}
              <div>
                <label htmlFor="name" className="block text-sm font-semibold">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={profileData.name}
                  onChange={handleProfileChange}
                  className="w-full p-2 rounded-md border bg-white text-gray-900"
                  placeholder="Your Name"
                />
              </div>

              {/* Phone Number */}
              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-semibold">Phone Number</label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={profileData.phoneNumber}
                  onChange={handleProfileChange}
                  className="w-full p-2 rounded-md border bg-white text-gray-900"
                  placeholder="Your Phone Number"
                />
              </div>

              <button
                type="submit"
                className="mt-6 px-6 py-2 bg-indigo-600 text-white font-semibold rounded-lg hover:bg-indigo-700 transition-all duration-300"
              >
                Update Profile
              </button>
            </form>

            {/* Status Message */}
            {profileStatus && (
              <div className="mt-4 text-lg font-semibold text-yellow-400">{profileStatus}</div>
            )}
          </div>

          {/* Security Settings Section */}
          <div className="mt-8 bg-gradient-to-b from-green-600 to-teal-800 p-6 rounded-lg text-white shadow-lg">
            <h2 className="text-2xl font-semibold">Security Settings</h2>
            <form className="space-y-6" onSubmit={handleSecuritySubmit}>
              {/* Old Password */}
              <div>
                <label htmlFor="oldPassword" className="block text-sm font-semibold">Old Password</label>
                <input
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  value={securityData.oldPassword}
                  onChange={handleSecurityChange}
                  className="w-full p-2 rounded-md border bg-white text-gray-900"
                  placeholder="Enter your old password"
                />
              </div>

              {/* New Password */}
              <div>
                <label htmlFor="newPassword" className="block text-sm font-semibold">New Password</label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={securityData.newPassword}
                  onChange={handleSecurityChange}
                  className="w-full p-2 rounded-md border bg-white text-gray-900"
                  placeholder="Enter new password"
                />
              </div>

              {/* Confirm New Password */}
              <div>
                <label htmlFor="confirmNewPassword" className="block text-sm font-semibold">Confirm New Password</label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  value={securityData.confirmNewPassword}
                  onChange={handleSecurityChange}
                  className="w-full p-2 rounded-md border bg-white text-gray-900"
                  placeholder="Confirm new password"
                />
              </div>

              <button
                type="submit"
                className="mt-6 px-6 py-2 bg-teal-600 text-white font-semibold rounded-lg hover:bg-teal-700 transition-all duration-300"
              >
                Update Password
              </button>
            </form>

            {/* Status Message */}
            {securityStatus && (
              <div className="mt-4 text-lg font-semibold text-yellow-400">{securityStatus}</div>
            )}
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default Settings;
