import React from 'react';
import { Link } from 'react-router-dom'; // Using Link for routing
import logo from '../assets/logo.png';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-pink-500 via-purple-600 to-black text-white py-8 mt-10">
      <div className="container mx-auto text-center">
        <Link to="/">
          <img
            src={logo}
            alt="AlgoTraderAi"
            className="w-24 mx-auto mb-4 transform transition-all duration-500 hover:scale-110 hover:rotate-6"
          />
        </Link>
        <p className="text-lg mb-6">
          &copy; {new Date().getFullYear()} AiAlgoTraders. All rights reserved.
        </p>
        <div className="flex justify-center space-x-8 mt-4">
          <Link
            to="/"
            className="hover:text-pink-300 transition-all duration-300 transform hover:scale-105"
          >
            Facebook
          </Link>
          <Link
            to="/"
            className="hover:text-pink-300 transition-all duration-300 transform hover:scale-105"
          >
            Twitter
          </Link>
          <Link
            to="/"
            className="hover:text-pink-300 transition-all duration-300 transform hover:scale-105"
          >
            LinkedIn
          </Link>
          <a
            href="https://www.instagram.com/aialgotraders?igsh=enE1MXM0MWF0cHo="
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-pink-300 transition-all duration-300 transform hover:scale-105"
          >
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
