import React, { useState } from 'react';
import DashboardLayout from './DashboardLayout';
import Footer from './Footer';
import { FaUserAlt, FaCheckCircle, FaCogs, FaWallet, FaRegClock } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Profile = () => {
  const [userData, setUserData] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    phoneNumber: '123-456-7890',
    username: 'johnny123',
    kycStatus: 'Verified',
    accountPlan: 'Premium',
    joinDate: 'January 1, 2023',
    lastLogin: 'December 14, 2024',
    country: 'USA',
    walletBalance: '$5,000.00',
    totalTransactions: '150',
  });

  // Define scroll animation variants
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <DashboardLayout>
      <div className="min-h-screen p-6 bg-gradient-to-b from-indigo-800 to-blue-700">
        <h1 className="text-3xl font-bold text-white">Profile</h1>
        <p className="mt-4 text-lg text-white">
          View and update your personal information and account details.
        </p>

        {/* Profile Information Section */}
        <motion.div
          className="mt-8 bg-gradient-to-b from-purple-600 to-blue-900 p-6 rounded-lg shadow-xl text-white"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <h2 className="text-2xl font-semibold flex items-center gap-2">
            <FaUserAlt /> Profile Information
          </h2>
          <div className="mt-6">
            <div className="flex justify-between items-center">
              <div className="text-sm text-gray-300">Username:</div>
              <div className="text-lg font-semibold">{userData.username}</div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <div className="text-sm text-gray-300">Name:</div>
              <div className="text-lg font-semibold">{userData.name}</div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <div className="text-sm text-gray-300">Email:</div>
              <div className="text-lg font-semibold">{userData.email}</div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <div className="text-sm text-gray-300">Phone Number:</div>
              <div className="text-lg font-semibold">{userData.phoneNumber}</div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <div className="text-sm text-gray-300">Country:</div>
              <div className="text-lg font-semibold">{userData.country}</div>
            </div>
          </div>
        </motion.div>

        {/* KYC Status Section */}
        <motion.div
          className="mt-8 bg-gradient-to-b from-teal-600 to-green-800 p-6 rounded-lg shadow-xl text-white"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <h2 className="text-2xl font-semibold flex items-center gap-2">
            <FaCheckCircle /> KYC Status
          </h2>
          <div className="mt-6 flex justify-between items-center">
            <div className="text-sm text-gray-300">Status:</div>
            <div className="text-lg font-semibold">{userData.kycStatus}</div>
          </div>
        </motion.div>

        {/* Account Plan Section */}
        <motion.div
          className="mt-8 bg-gradient-to-b from-green-600 to-teal-900 p-6 rounded-lg shadow-xl text-white"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <h2 className="text-2xl font-semibold flex items-center gap-2">
            <FaCogs /> Account Plan
          </h2>
          <div className="mt-6 flex justify-between items-center">
            <div className="text-sm text-gray-300">Plan:</div>
            <div className="text-lg font-semibold">{userData.accountPlan}</div>
          </div>
        </motion.div>

        {/* Wallet and Transaction Info Section */}
        <motion.div
          className="mt-8 bg-gradient-to-b from-indigo-600 to-purple-800 p-6 rounded-lg shadow-xl text-white"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <h2 className="text-2xl font-semibold flex items-center gap-2">
            <FaWallet /> Wallet & Transaction Info
          </h2>
          <div className="mt-6">
            <div className="flex justify-between items-center">
              <div className="text-sm text-gray-300">Wallet Balance:</div>
              <div className="text-lg font-semibold">{userData.walletBalance}</div>
            </div>
            <div className="flex justify-between items-center mt-4">
              <div className="text-sm text-gray-300">Total Transactions:</div>
              <div className="text-lg font-semibold">{userData.totalTransactions}</div>
            </div>
          </div>
        </motion.div>

        {/* Activity Section */}
        <motion.div
          className="mt-8 bg-gradient-to-b from-blue-600 to-indigo-800 p-6 rounded-lg shadow-xl text-white"
          variants={sectionVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <h2 className="text-2xl font-semibold flex items-center gap-2">
            <FaRegClock /> Activity
          </h2>
          <div className="mt-6 flex justify-between items-center">
            <div className="text-sm text-gray-300">Last Login:</div>
            <div className="text-lg font-semibold">{userData.lastLogin}</div>
          </div>
          <div className="flex justify-between items-center mt-4">
            <div className="text-sm text-gray-300">Joined:</div>
            <div className="text-lg font-semibold">{userData.joinDate}</div>
          </div>
        </motion.div>

        {/* Footer */}
        <Footer />
      </div>
    </DashboardLayout>
  );
};

export default Profile;
