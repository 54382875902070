import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import logo from '../assets/logo.png';

// Menu items for the user dropdown
const USER_MENU = [
  { name: 'Profile', path: '/profile' },
  { name: 'Settings', path: '/settings' },
  { name: 'KYC', path: '/kyc' },
  { name: 'Logout', path: '/' },
];

// Reusable MenuItem component to avoid duplication
const MenuItem = ({ to, children }) => (
  <Link
    to={to}
    className="block text-lg md:text-xl lg:text-2xl font-semibold hover:text-pink-300 hover:scale-105 transition-transform duration-300 ease-in-out"
  >
    {children}
  </Link>
);

const DashboardHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const menuRef = useRef(null);
  const userMenuRef = useRef(null);

  // Close menus when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        userMenuRef.current &&
        !userMenuRef.current.contains(e.target)
      ) {
        setIsMobileMenuOpen(false);
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Mobile menu toggle
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-pink-400 to-purple-600 text-white p-4 shadow-2xl relative">
      <div className="container mx-auto flex justify-between items-center">
        {/* Mobile Hamburger Menu Button */}
        <button
          className={`md:hidden text-white hover:animate-pulse transition-transform transform ${
            isMobileMenuOpen ? 'rotate-90' : ''
          }`}
          onClick={handleMobileMenuToggle}
          aria-expanded={isMobileMenuOpen}
          aria-controls="mobile-menu"
          aria-label="Toggle Navigation"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-8 h-8"
          >
            {isMobileMenuOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>

        {/* Logo on the far left */}
        <Link
          to="/dashboard"
          className="w-36 transform transition-transform duration-500 hover:scale-110 flex justify-center items-center"
        >
          <img src={logo} alt="AlgoTraderAi" className="w-full" />
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8 justify-center items-center w-full">
          <MenuItem to="/dashboard">Dashboard</MenuItem>
          <MenuItem to="/financials">Financials</MenuItem>
          <MenuItem to="/referrals">Referrals</MenuItem>
          <MenuItem to="/trading">Trading</MenuItem>
          <MenuItem to="/documents">Documents</MenuItem>
          <MenuItem to="/education">Education</MenuItem>
        </nav>

        {/* User Icon on the far right */}
        <div className="relative ml-auto flex items-center justify-center" ref={userMenuRef}>
          <button
            className="flex items-center space-x-2 text-white transform transition-transform hover:scale-110"
            onClick={() => setIsUserMenuOpen((prev) => !prev)}
            aria-expanded={isUserMenuOpen}
            aria-controls="user-menu"
            aria-label="User Menu"
            aria-haspopup="true"
          >
            <FaUserCircle
              className={`w-12 h-12 ${isUserMenuOpen ? 'animate-spin' : ''} hover:text-pink-300 transition-colors`}
            />
          </button>

          {/* User Dropdown Menu */}
          {isUserMenuOpen && (
            <div
              id="user-menu"
              className="absolute right-0 top-12 bg-gradient-to-r from-pink-400 to-purple-600 text-white w-40 shadow-lg rounded-lg z-50 animate-fadeIn"
            >
              {USER_MENU.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className="block px-4 py-2 text-lg md:text-xl lg:text-2xl font-semibold hover:bg-pink-500 hover:text-white transition-all duration-300 rounded-md"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMobileMenuOpen && (
        <div
          ref={menuRef}
          id="mobile-menu"
          className="absolute top-16 left-0 bg-gradient-to-r from-pink-400 to-purple-600 text-white w-64 shadow-xl p-4 space-y-4 md:hidden rounded-r-lg z-50 transform transition-transform animate-slideInLeft delay-200"
        >
          <MenuItem to="/dashboard">Dashboard</MenuItem>
          <MenuItem to="/financials">Financials</MenuItem>
          <MenuItem to="/referrals">Referrals</MenuItem>
          <MenuItem to="/trading">Trading</MenuItem>
          <MenuItem to="/documents">Documents</MenuItem>
          <MenuItem to="/education">Education</MenuItem>
        </div>
      )}
    </header>
  );
};

export default DashboardHeader;
